import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import DetailImage from './DetailImage'
// import { useHistory, useLocation, useParams } from 'react-router-dom'

const DetailImageList = observer(() => {
  const [html, setHtml] = useState('')
  //   const history = useHistory()
  //   const { itemId } = useParams()
  //   useEffect(() => {
  //     // if (itemId) {
  //     //   ItemStore.loadTargetItemInfo(itemId)
  //     // }
  //   }, [])
  useEffect(() => {
    let newHtml =
      '<meta name="viewport" content="width=device-width, initial-scale=1">'
    const defaultHtml = `
  <style>
    img { 
      max-width: 100%;
      height: auto !important;
      vertical-align: top;
    }
    * {
      user-select: none;
      -webkit-user-select: none;
      max-width: 100vw;
    }
    body, html { margin:0; padding:0; background:#f5f5f5 !important; }
    html { overflow:scroll !important; }
  </style>
`

    if (
      typeof ItemStore.targetItemInfo?.detailHTML === 'string' &&
      ItemStore.targetItemInfo?.detailHTML.length > 0
    ) {
      setHtml(newHtml + ItemStore.targetItemInfo.detailHTML + defaultHtml)
    } else if (Array.isArray(ItemStore.targetItemInfo?.detailImageUris)) {
      ItemStore.targetItemInfo.detailImageUris.forEach((each) => {
        newHtml += `<img src="${each}" />`
      })
      setHtml(newHtml + defaultHtml)
    }
  }, [
    ItemStore.targetItemInfo?.detailImageUris?.length,
    ItemStore.targetItemInfo?.detailHTML?.length,
  ])
  return (
    <div>
      {/* {ItemStore.targetItemInfo.detailImageUris?.map((detailImageUri) => (
        // <div key={detailImageUri}>
        //   <img
        //     alt={detailImageUri}
        //     src={detailImageUri}
        //     //   style={{ objectFit: 'cover' }}
        //     width='100%'
        //     //   height='200'
        //   />
        // </div>
        <DetailImage key={detailImageUri} detailImageUri={detailImageUri} />
      ))} */}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
})

export default DetailImageList
